
.checkImage {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
}

.link {
    display: flex;
    align-items: center;
    gap: 8px;
}

.link a {
    color: #444;
    text-decoration: none;
}

.link svg {
    width: 24px;
    height: auto;
}