.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  pointer-events: all;
  z-index: 10001;
  word-break: keep-all;
}

.wrapper {
  background-color: #ffffff;
  height: fit-content;
  border-radius: 20px;
  width: 520px;
  padding: 24px;
}
.content {
  position: relative;
  white-space: pre-wrap;
}
.cross {
  position: absolute;
  right: 0;
  top: 0;
}
.cross:hover {
  cursor: pointer;
}
.content h3 {
  font-weight: 500;
  font-size: 18px;
  color: #2c2e35;
}

.content p {
  overflow-wrap: break-word;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #2c2e35;
  white-space: normal;
}

@media (max-width: 768px) {
  .background {
    box-sizing: border-box;
  }
  .wrapper {
    width: 100%;
    margin: 16px;

    max-height: calc(100% - 32px);
    overflow: auto;
  }
}
